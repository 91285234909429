
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
class Services extends Vue {
  get services() {
    return [
      {
        name: '<span class="is-block-desktop">Individualno savjetovanje</span> <span class="is-block-desktop"> za mlade punoljetne osobe,</span> studente i nezaposlene',
        price: '25€',
        duration: '60 minuta',
      },
      // {
      //   name: '<span class="is-block-desktop">Individualno savjetovanje</span> za mlade do 18 godina',
      //   price: '27€',
      //   duration: '60 minuta',
      // },
      {
        name: '<span class="is-block-desktop">Individualno savjetovanje</span> za zaposlene',
        price: '35€',
        duration: '60 minuta',
      },
      {
        name: '<span class="is-block-desktop">Partnersko savjetovanje</span>',
        price: '55€',
        duration: '90 minuta',
      },
    ];
  }
}

export default Services;
